
import { defineComponent } from "vue";

import HelloWorldOptions from "@/components/HelloWorldOptions.vue";
import HelloWorldComposition from "@/components/HelloWorldComposition.vue";

import { typedTemplateRefs } from "@/util/vue";

// Note: Needs to be `type`, not `interface`; otherwise `this.$refs as Refs` fails.
type Refs = {
  helloOptions: InstanceType<typeof HelloWorldOptions>;
  helloComposition: InstanceType<typeof HelloWorldComposition>;
};

export default defineComponent({
  name: "About",
  components: {
    HelloWorldComposition,
    HelloWorldOptions,
  },
  mounted() {
    const refs = this.$refs as Refs;

    refs.helloOptions.sayHello();
    refs.helloComposition.sayHello();

    // or, using computed:
    this.refs.helloOptions.sayHello();
    this.refs.helloComposition.sayHello();
  },
  computed: {
    ...typedTemplateRefs<{
      helloOptions: InstanceType<typeof HelloWorldOptions>;
      helloComposition: InstanceType<typeof HelloWorldComposition>;
    }>(),
    // ...typedTemplateRefs<Refs>(), would also be valid
  },
});
